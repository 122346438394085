const campaigns = [
  {
    processedCount: 53034,
    deliveredCount: 53034,
    openedCount: 5625,
    unopenedCount: 47409,
    clickedCount: 690,
    processedPercentage: "100 %",
    openedPercentage: "10.61",
    unopenedPercentage: "89.39",
    clickedPercentage: "12.27 %",
    editMode: "tool2",
    editType: "full",
    id: 256362,
    idType: "Campaign",
    sendTimeStart: "July 20, 2021 at 08h02 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 24954,
    deliveredCount: 24954,
    openedCount: 3236,
    unopenedCount: 21718,
    clickedCount: 154,
    processedPercentage: "100 %",
    openedPercentage: "12.97",
    unopenedPercentage: "87.03",
    clickedPercentage: "4.76 %",
    editMode: "tool2",
    editType: "full",
    id: 256312,
    idType: "Campaign",
    sendTimeStart: "July 20, 2021 at 06h15 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
    title:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
  },
  {
    processedCount: 24976,
    deliveredCount: 24976,
    openedCount: 3087,
    unopenedCount: 21889,
    clickedCount: 153,
    processedPercentage: "100 %",
    openedPercentage: "12.36",
    unopenedPercentage: "87.64",
    clickedPercentage: "4.96 %",
    editMode: "tool2",
    editType: "full",
    id: 256292,
    idType: "Campaign",
    sendTimeStart: "July 20, 2021 at 05h45 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
    title:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
  },
  {
    processedCount: 24976,
    deliveredCount: 24976,
    openedCount: 3263,
    unopenedCount: 21713,
    clickedCount: 165,
    processedPercentage: "100 %",
    openedPercentage: "13.06",
    unopenedPercentage: "86.94",
    clickedPercentage: "5.06 %",
    editMode: "tool2",
    editType: "full",
    id: 256286,
    idType: "Campaign",
    sendTimeStart: "July 20, 2021 at 05h20 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
    title:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
  },
  {
    processedCount: 24949,
    deliveredCount: 24949,
    openedCount: 3147,
    unopenedCount: 21802,
    clickedCount: 139,
    processedPercentage: "100 %",
    openedPercentage: "12.61",
    unopenedPercentage: "87.39",
    clickedPercentage: "4.42 %",
    editMode: "tool2",
    editType: "full",
    id: 256276,
    idType: "Campaign",
    sendTimeStart: "July 20, 2021 at 05h00 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
    title:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
  },
  {
    processedCount: 24951,
    deliveredCount: 24951,
    openedCount: 3179,
    unopenedCount: 21772,
    clickedCount: 159,
    processedPercentage: "100 %",
    openedPercentage: "12.74",
    unopenedPercentage: "87.26",
    clickedPercentage: "5.00 %",
    editMode: "tool2",
    editType: "full",
    id: 256268,
    idType: "Campaign",
    sendTimeStart: "July 20, 2021 at 04h32 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
    title:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
  },
  {
    processedCount: 4,
    deliveredCount: 4,
    openedCount: 4,
    unopenedCount: 0,
    clickedCount: 1,
    processedPercentage: "100 %",
    openedPercentage: "100.00",
    unopenedPercentage: "0.00",
    clickedPercentage: "25.00 %",
    editMode: "tool2",
    editType: "full",
    id: 256264,
    idType: "Campaign",
    sendTimeStart: "July 20, 2021 at 04h29 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
    title:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
  },
  {
    processedCount: 25000,
    deliveredCount: 25000,
    openedCount: 3399,
    unopenedCount: 21601,
    clickedCount: 152,
    processedPercentage: "100 %",
    openedPercentage: "13.60",
    unopenedPercentage: "86.40",
    clickedPercentage: "4.47 %",
    editMode: "tool2",
    editType: "full",
    id: 255672,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 09h35 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 25000,
    deliveredCount: 25000,
    openedCount: 3354,
    unopenedCount: 21646,
    clickedCount: 139,
    processedPercentage: "100 %",
    openedPercentage: "13.42",
    unopenedPercentage: "86.58",
    clickedPercentage: "4.14 %",
    editMode: "tool2",
    editType: "full",
    id: 255662,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 09h15 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 25000,
    deliveredCount: 25000,
    openedCount: 3383,
    unopenedCount: 21617,
    clickedCount: 167,
    processedPercentage: "100 %",
    openedPercentage: "13.53",
    unopenedPercentage: "86.47",
    clickedPercentage: "4.94 %",
    editMode: "tool2",
    editType: "full",
    id: 255632,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 08h45 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 25000,
    deliveredCount: 25000,
    openedCount: 3382,
    unopenedCount: 21618,
    clickedCount: 185,
    processedPercentage: "100 %",
    openedPercentage: "13.53",
    unopenedPercentage: "86.47",
    clickedPercentage: "5.47 %",
    editMode: "tool2",
    editType: "full",
    id: 255624,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 08h30 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 25000,
    deliveredCount: 25000,
    openedCount: 3407,
    unopenedCount: 21593,
    clickedCount: 172,
    processedPercentage: "100 %",
    openedPercentage: "13.63",
    unopenedPercentage: "86.37",
    clickedPercentage: "5.05 %",
    editMode: "tool2",
    editType: "full",
    id: 255610,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 08h10 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 25000,
    deliveredCount: 25000,
    openedCount: 3448,
    unopenedCount: 21552,
    clickedCount: 194,
    processedPercentage: "100 %",
    openedPercentage: "13.79",
    unopenedPercentage: "86.21",
    clickedPercentage: "5.63 %",
    editMode: "tool2",
    editType: "full",
    id: 255594,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 07h52 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 25000,
    deliveredCount: 25000,
    openedCount: 3454,
    unopenedCount: 21546,
    clickedCount: 168,
    processedPercentage: "100 %",
    openedPercentage: "13.82",
    unopenedPercentage: "86.18",
    clickedPercentage: "4.86 %",
    editMode: "tool2",
    editType: "full",
    id: 255574,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 07h35 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 25000,
    deliveredCount: 25000,
    openedCount: 3180,
    unopenedCount: 21820,
    clickedCount: 213,
    processedPercentage: "100 %",
    openedPercentage: "12.72",
    unopenedPercentage: "87.28",
    clickedPercentage: "6.70 %",
    editMode: "tool2",
    editType: "full",
    id: 255560,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 07h15 UTC",
    starred: false,
    status: "Sent",
    subject:
      "This summer for AED 1,600 return fare, get away to Salzburg with flydubai",
    title:
      "This summer for AED 1,600 return fare, get away to Salzburg with flydubai",
  },
  {
    processedCount: 25000,
    deliveredCount: 25000,
    openedCount: 3042,
    unopenedCount: 21958,
    clickedCount: 521,
    processedPercentage: "100 %",
    openedPercentage: "12.17",
    unopenedPercentage: "87.83",
    clickedPercentage: "17.13 %",
    editMode: "tool2",
    editType: "full",
    id: 255546,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 07h00 UTC",
    starred: false,
    status: "Sent",
    subject:
      "This summer for AED 1,600 return fare, get away to Salzburg with flydubai",
    title:
      "This summer for AED 1,600 return fare, get away to Salzburg with flydubai",
  },
  {
    processedCount: 3000,
    deliveredCount: 3000,
    openedCount: 502,
    unopenedCount: 2498,
    clickedCount: 151,
    processedPercentage: "100 %",
    openedPercentage: "16.73",
    unopenedPercentage: "83.27",
    clickedPercentage: "30.08 %",
    editMode: "tool2",
    editType: "full",
    id: 255514,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 06h00 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Summer getaways starting at 1495 AED return with visa on arrival!",
    title: "Summer getaways starting at 1495 AED return with visa on arrival!",
  },
  {
    processedCount: 9999,
    deliveredCount: 9999,
    openedCount: 1286,
    unopenedCount: 8713,
    clickedCount: 55,
    processedPercentage: "100 %",
    openedPercentage: "12.86",
    unopenedPercentage: "87.14",
    clickedPercentage: "4.28 %",
    editMode: "tool2",
    editType: "full",
    id: 255508,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 05h55 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
    title:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
  },
  {
    processedCount: 10001,
    deliveredCount: 10001,
    openedCount: 1314,
    unopenedCount: 8687,
    clickedCount: 58,
    processedPercentage: "100 %",
    openedPercentage: "13.14",
    unopenedPercentage: "86.86",
    clickedPercentage: "4.41 %",
    editMode: "tool2",
    editType: "full",
    id: 255502,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 05h45 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
    title:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
  },
  {
    processedCount: 10000,
    deliveredCount: 10000,
    openedCount: 1271,
    unopenedCount: 8729,
    clickedCount: 59,
    processedPercentage: "100 %",
    openedPercentage: "12.71",
    unopenedPercentage: "87.29",
    clickedPercentage: "4.64 %",
    editMode: "tool2",
    editType: "full",
    id: 255496,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 05h30 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
    title:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
  },
  {
    processedCount: 10000,
    deliveredCount: 10000,
    openedCount: 1282,
    unopenedCount: 8718,
    clickedCount: 55,
    processedPercentage: "100 %",
    openedPercentage: "12.82",
    unopenedPercentage: "87.18",
    clickedPercentage: "4.29 %",
    editMode: "tool2",
    editType: "full",
    id: 255494,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 05h15 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
    title:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
  },
  {
    processedCount: 9996,
    deliveredCount: 9996,
    openedCount: 1173,
    unopenedCount: 8823,
    clickedCount: 48,
    processedPercentage: "100 %",
    openedPercentage: "11.73",
    unopenedPercentage: "88.27",
    clickedPercentage: "4.09 %",
    editMode: "tool2",
    editType: "full",
    id: 255482,
    idType: "Campaign",
    sendTimeStart: "July 19, 2021 at 04h50 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
    title:
      "EID getaways to visa on arrival destinations with flydubai! Baku, Mykonos, Santorini, Tirana!",
  },
  {
    processedCount: 50011,
    deliveredCount: 50011,
    openedCount: 5240,
    unopenedCount: 44771,
    clickedCount: 550,
    processedPercentage: "100 %",
    openedPercentage: "10.48",
    unopenedPercentage: "89.52",
    clickedPercentage: "10.50 %",
    editMode: "tool2",
    editType: "full",
    id: 253648,
    idType: "Campaign",
    sendTimeStart: "July 18, 2021 at 02h00 UTC",
    starred: false,
    status: "Sent",
    subject:
      "This summer for AED 1,600 return fare, get away to Salzburg with flydubai",
    title:
      "This summer for AED 1,600 return fare, get away to Salzburg with flydubai",
  },
  {
    processedCount: 25021,
    deliveredCount: 25021,
    openedCount: 2575,
    unopenedCount: 22446,
    clickedCount: 363,
    processedPercentage: "100 %",
    openedPercentage: "10.29",
    unopenedPercentage: "89.71",
    clickedPercentage: "14.10 %",
    editMode: "tool2",
    editType: "full",
    id: 253548,
    idType: "Campaign",
    sendTimeStart: "July 16, 2021 at 09h00 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 24089,
    deliveredCount: 24089,
    openedCount: 3270,
    unopenedCount: 20819,
    clickedCount: 442,
    processedPercentage: "100 %",
    openedPercentage: "13.57",
    unopenedPercentage: "86.43",
    clickedPercentage: "13.52 %",
    editMode: "tool2",
    editType: "full",
    id: 253508,
    idType: "Campaign",
    sendTimeStart: "July 16, 2021 at 08h30 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 25059,
    deliveredCount: 25059,
    openedCount: 2956,
    unopenedCount: 22103,
    clickedCount: 425,
    processedPercentage: "100 %",
    openedPercentage: "11.80",
    unopenedPercentage: "88.20",
    clickedPercentage: "14.38 %",
    editMode: "tool2",
    editType: "full",
    id: 253499,
    idType: "Campaign",
    sendTimeStart: "July 16, 2021 at 07h40 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 25189,
    deliveredCount: 25189,
    openedCount: 2985,
    unopenedCount: 22204,
    clickedCount: 393,
    processedPercentage: "100 %",
    openedPercentage: "11.85",
    unopenedPercentage: "88.15",
    clickedPercentage: "13.17 %",
    editMode: "tool2",
    editType: "full",
    id: 253478,
    idType: "Campaign",
    sendTimeStart: "July 16, 2021 at 06h56 UTC",
    starred: false,
    status: "Sent",
    subject:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
    title:
      "EID getaways starting at AED 1025 return, to visa on arrival destinations!",
  },
  {
    processedCount: 50085,
    deliveredCount: 50085,
    openedCount: 6832,
    unopenedCount: 43253,
    clickedCount: 1182,
    processedPercentage: "100 %",
    openedPercentage: "13.64",
    unopenedPercentage: "86.36",
    clickedPercentage: "17.30 %",
    editMode: "tool2",
    editType: "full",
    id: 253468,
    idType: "Campaign",
    sendTimeStart: "July 15, 2021 at 03h02 UTC",
    starred: false,
    status: "Sent",
    subject: "Dubai to Salzburg, AED 1,600 return fare with flydubai",
    title: "Dubai to Salzburg, AED 1,600 return fare with flydubai",
  },
  {
    processedCount: 50093,
    deliveredCount: 50093,
    openedCount: 6254,
    unopenedCount: 43839,
    clickedCount: 887,
    processedPercentage: "100 %",
    openedPercentage: "12.48",
    unopenedPercentage: "87.52",
    clickedPercentage: "14.18 %",
    editMode: "tool2",
    editType: "full",
    id: 253466,
    idType: "Campaign",
    sendTimeStart: "July 15, 2021 at 03h01 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Getaway to visa on arrival destinations with flydubai! Trabzon, Bodrum, Batumi!",
    title:
      "Getaway to visa on arrival destinations with flydubai! Trabzon, Bodrum, Batumi!",
  },
  {
    processedCount: 50171,
    deliveredCount: 50171,
    openedCount: 5047,
    unopenedCount: 45124,
    clickedCount: 537,
    processedPercentage: "100 %",
    openedPercentage: "10.06",
    unopenedPercentage: "89.94",
    clickedPercentage: "10.64 %",
    editMode: "tool2",
    editType: "full",
    id: 251890,
    idType: "Campaign",
    sendTimeStart: "July 12, 2021 at 22h45 UTC",
    starred: false,
    status: "Sent",
    subject: "Dubai to Salzburg, AED 1,600 return fare with flydubai",
    title: "Dubai to Salzburg, AED 1,600 return fare with flydubai",
  },
  {
    processedCount: 50184,
    deliveredCount: 50184,
    openedCount: 6460,
    unopenedCount: 43724,
    clickedCount: 648,
    processedPercentage: "100 %",
    openedPercentage: "12.87",
    unopenedPercentage: "87.13",
    clickedPercentage: "10.03 %",
    editMode: "tool2",
    editType: "full",
    id: 250952,
    idType: "Campaign",
    sendTimeStart: "July 11, 2021 at 03h59 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Tbilisi, Tirana, Santorini, get visa on arrival. Book your summer getaway!",
    title:
      "Tbilisi, Tirana, Santorini, get visa on arrival. Book your summer getaway!",
  },
  {
    processedCount: 5,
    deliveredCount: 5,
    openedCount: 3,
    unopenedCount: 2,
    clickedCount: 2,
    processedPercentage: "100 %",
    openedPercentage: "60.00",
    unopenedPercentage: "40.00",
    clickedPercentage: "66.67 %",
    editMode: "tool2",
    editType: "full",
    id: 250842,
    idType: "Campaign",
    sendTimeStart: "July 10, 2021 at 12h40 UTC",
    starred: false,
    status: "Sent",
    subject: "Dubai to Salzburg, AED 1,600 return fare with flydubai",
    title: "Dubai to Salzburg, AED 1,600 return fare with flydubai",
  },
  {
    processedCount: 4,
    deliveredCount: 4,
    openedCount: 4,
    unopenedCount: 0,
    clickedCount: 2,
    processedPercentage: "100 %",
    openedPercentage: "100.00",
    unopenedPercentage: "0.00",
    clickedPercentage: "50.00 %",
    editMode: "tool2",
    editType: "full",
    id: 250820,
    idType: "Campaign",
    sendTimeStart: "July 10, 2021 at 11h06 UTC",
    starred: false,
    status: "Sent",
    subject: "Dubai to Salzburg, AED 1,600 return fare with flydubai",
    title: "Dubai to Salzburg, AED 1,600 return fare with flydubai",
  },
  {
    processedCount: 50115,
    deliveredCount: 50115,
    openedCount: 8975,
    unopenedCount: 41140,
    clickedCount: 361,
    processedPercentage: "100 %",
    openedPercentage: "17.91",
    unopenedPercentage: "82.09",
    clickedPercentage: "4.02 %",
    editMode: "tool2",
    editType: "full",
    id: 249336,
    idType: "Campaign",
    sendTimeStart: "July 8, 2021 at 03h56 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Trabzon, Batumi, Bodrum, get visa on arrival to these summer getaways!",
    title:
      "Trabzon, Batumi, Bodrum, get visa on arrival to these summer getaways!",
  },
  {
    processedCount: 50172,
    deliveredCount: 50172,
    openedCount: 4606,
    unopenedCount: 45566,
    clickedCount: 540,
    processedPercentage: "100 %",
    openedPercentage: "9.18",
    unopenedPercentage: "90.82",
    clickedPercentage: "11.72 %",
    editMode: "tool2",
    editType: "full",
    id: 248532,
    idType: "Campaign",
    sendTimeStart: "July 7, 2021 at 03h54 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Getaway to visa on arrival destinations with flydubai! Tbilisi, Tirana, Santorini, Mykonos!",
    title:
      "Getaway to visa on arrival destinations with flydubai! Tbilisi, Tirana, Santorini, Mykonos!",
  },
  {
    processedCount: 49785,
    deliveredCount: 49785,
    openedCount: 5724,
    unopenedCount: 44061,
    clickedCount: 603,
    processedPercentage: "100 %",
    openedPercentage: "11.50",
    unopenedPercentage: "88.50",
    clickedPercentage: "10.53 %",
    editMode: "tool2",
    editType: "full",
    id: 247786,
    idType: "Campaign",
    sendTimeStart: "July 6, 2021 at 03h43 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Summer getaways starting at 1300 AED return with visa on arrival!",
    title: "Summer getaways starting at 1300 AED return with visa on arrival!",
  },
  {
    processedCount: 49982,
    deliveredCount: 49982,
    openedCount: 6076,
    unopenedCount: 43906,
    clickedCount: 787,
    processedPercentage: "100 %",
    openedPercentage: "12.16",
    unopenedPercentage: "87.84",
    clickedPercentage: "12.95 %",
    editMode: "tool2",
    editType: "full",
    id: 247020,
    idType: "Campaign",
    sendTimeStart: "July 5, 2021 at 03h00 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Summer getaways starting at 1495 AED return with visa on arrival!",
    title: "Summer getaways starting at 1495 AED return with visa on arrival!",
  },
  {
    processedCount: 4,
    deliveredCount: 4,
    openedCount: 4,
    unopenedCount: 0,
    clickedCount: 0,
    processedPercentage: "100 %",
    openedPercentage: "100.00",
    unopenedPercentage: "0.00",
    clickedPercentage: "0.00 %",
    editMode: "tool2",
    editType: "full",
    id: 246900,
    idType: "Campaign",
    sendTimeStart: "July 4, 2021 at 16h06 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Trabzon, Batumi, Bodrum, get visa on arrival to these summer getaways!",
    title:
      "Trabzon, Batumi, Bodrum, get visa on arrival to these summer getaways!",
  },
  {
    processedCount: 50079,
    deliveredCount: 50079,
    openedCount: 5809,
    unopenedCount: 44270,
    clickedCount: 790,
    processedPercentage: "100 %",
    openedPercentage: "11.60",
    unopenedPercentage: "88.40",
    clickedPercentage: "13.60 %",
    editMode: "tool2",
    editType: "full",
    id: 246738,
    idType: "Campaign",
    sendTimeStart: "July 4, 2021 at 03h00 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Getaway to visa on arrival destinations with flydubai! Trabzon, Bodrum, Batumi!",
    title:
      "Getaway to visa on arrival destinations with flydubai! Trabzon, Bodrum, Batumi!",
  },
  {
    processedCount: 49998,
    deliveredCount: 49998,
    openedCount: 4994,
    unopenedCount: 45004,
    clickedCount: 658,
    processedPercentage: "100 %",
    openedPercentage: "9.99",
    unopenedPercentage: "90.01",
    clickedPercentage: "13.18 %",
    editMode: "tool2",
    editType: "full",
    id: 244558,
    idType: "Campaign",
    sendTimeStart: "July 1, 2021 at 03h58 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Tbilisi, Tirana, Santorini, get visa on arrival. Book your summer getaway!",
    title:
      "Tbilisi, Tirana, Santorini, get visa on arrival. Book your summer getaway!",
  },
  {
    processedCount: 49636,
    deliveredCount: 49636,
    openedCount: 5256,
    unopenedCount: 44380,
    clickedCount: 980,
    processedPercentage: "100 %",
    openedPercentage: "10.59",
    unopenedPercentage: "89.41",
    clickedPercentage: "18.65 %",
    editMode: "tool2",
    editType: "full",
    id: 243760,
    idType: "Campaign",
    sendTimeStart: "June 30, 2021 at 03h00 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Trabzon, Batumi, Bodrum, get visa on arrival to these summer getaways!",
    title:
      "Trabzon, Batumi, Bodrum, get visa on arrival to these summer getaways!",
  },
  {
    processedCount: 47799,
    deliveredCount: 47799,
    openedCount: 5153,
    unopenedCount: 42646,
    clickedCount: 854,
    processedPercentage: "100 %",
    openedPercentage: "10.78",
    unopenedPercentage: "89.22",
    clickedPercentage: "16.57 %",
    editMode: "tool2",
    editType: "full",
    id: 242812,
    idType: "Campaign",
    sendTimeStart: "June 29, 2021 at 06h00 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Getaway to visa on arrival destinations with flydubai! Tbilisi, Tirana, Santorini, Mykonos!",
    title:
      "Getaway to visa on arrival destinations with flydubai! Tbilisi, Tirana, Santorini, Mykonos!",
  },
  {
    processedCount: 5,
    deliveredCount: 5,
    openedCount: 5,
    unopenedCount: 0,
    clickedCount: 1,
    processedPercentage: "100 %",
    openedPercentage: "100.00",
    unopenedPercentage: "0.00",
    clickedPercentage: "20.00 %",
    editMode: "tool2",
    editType: "full",
    id: 241626,
    idType: "Campaign",
    sendTimeStart: "June 27, 2021 at 15h54 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Getaway to visa on arrival destinations with flydubai! Tbilisi, Tirana, Santorini, Mykonos!",
    title:
      "Getaway to visa on arrival destinations with flydubai! Tbilisi, Tirana, Santorini, Mykonos!",
  },
  {
    processedCount: 4,
    deliveredCount: 4,
    openedCount: 4,
    unopenedCount: 0,
    clickedCount: 0,
    processedPercentage: "100 %",
    openedPercentage: "100.00",
    unopenedPercentage: "0.00",
    clickedPercentage: "0.00 %",
    editMode: "tool2",
    editType: "full",
    id: 241618,
    idType: "Campaign",
    sendTimeStart: "June 27, 2021 at 15h38 UTC",
    starred: false,
    status: "Sent",
    subject:
      "Getaway to visa on arrival destinations with flydubai! Tbilisi, Tirana, Santorini, Mykonos!",
    title:
      "Getaway to visa on arrival destinations with flydubai! Tbilisi, Tirana, Santorini, Mykonos!",
  },
  {
    processedCount: 51954,
    deliveredCount: 51954,
    openedCount: 5396,
    unopenedCount: 46558,
    clickedCount: 398,
    processedPercentage: "100 %",
    openedPercentage: "10.39",
    unopenedPercentage: "89.61",
    clickedPercentage: "7.38 %",
    editMode: "tool2",
    editType: "full",
    id: 241496,
    idType: "Campaign",
    sendTimeStart: "June 27, 2021 at 08h24 UTC",
    starred: false,
    status: "Sent",
    subject: "Flydubai flights to Iran with return fares starting at AED 1,304",
    title: "Flydubai flights to Iran with return fares starting at AED 1,304",
  },
  {
    processedCount: 50148,
    deliveredCount: 50148,
    openedCount: 4337,
    unopenedCount: 45811,
    clickedCount: 352,
    processedPercentage: "100 %",
    openedPercentage: "8.65",
    unopenedPercentage: "91.35",
    clickedPercentage: "8.12 %",
    editMode: "tool2",
    editType: "full",
    id: 241170,
    idType: "Campaign",
    sendTimeStart: "June 26, 2021 at 06h00 UTC",
    starred: false,
    status: "Sent",
    subject: "Fly to Iran with return fares as low as AED 1,304",
    title: "Fly to Iran with return fares as low as AED 1,304",
  },
  {
    processedCount: 50142,
    deliveredCount: 50142,
    openedCount: 3690,
    unopenedCount: 46452,
    clickedCount: 144,
    processedPercentage: "100 %",
    openedPercentage: "7.36",
    unopenedPercentage: "92.64",
    clickedPercentage: "3.90 %",
    editMode: "tool2",
    editType: "full",
    id: 239662,
    idType: "Campaign",
    sendTimeStart: "June 24, 2021 at 06h30 UTC",
    starred: false,
    status: "Sent",
    subject: "Flydubai flights to Iran with return fares starting at AED 1,304",
    title: "Flydubai flights to Iran with return fares starting at AED 1,304",
  },
  {
    processedCount: 49997,
    deliveredCount: 49997,
    openedCount: 13089,
    unopenedCount: 36908,
    clickedCount: 231,
    processedPercentage: "100 %",
    openedPercentage: "26.18",
    unopenedPercentage: "73.82",
    clickedPercentage: "1.76 %",
    editMode: "tool2",
    editType: "full",
    id: 238824,
    idType: "Campaign",
    sendTimeStart: "June 23, 2021 at 06h00 UTC",
    starred: false,
    status: "Sent",
    subject: "Fly to Iran with return fares as low as AED 1,304",
    title: "Fly to Iran with return fares as low as AED 1,304",
  },
  {
    processedCount: 100000,
    deliveredCount: 100000,
    openedCount: 10900,
    unopenedCount: 89100,
    clickedCount: 2767,
    processedPercentage: "100 %",
    openedPercentage: "10.90",
    unopenedPercentage: "89.10",
    clickedPercentage: "25.39 %",
    editMode: "tool2",
    editType: "full",
    id: 238746,
    idType: "Campaign",
    sendTimeStart: "June 23, 2021 at 03h00 UTC",
    starred: false,
    status: "Sent",
    subject: "Dubai to Sharm El Sheikh for AED 1,300 with flydubai",
    title: "Dubai to Sharm El Sheikh for AED 1,300 with flydubai",
  },
  {
    processedCount: 5,
    deliveredCount: 5,
    openedCount: 4,
    unopenedCount: 1,
    clickedCount: 1,
    processedPercentage: "100 %",
    openedPercentage: "80.00",
    unopenedPercentage: "20.00",
    clickedPercentage: "25.00 %",
    editMode: "tool2",
    editType: "full",
    id: 238626,
    idType: "Campaign",
    sendTimeStart: "June 22, 2021 at 18h29 UTC",
    starred: false,
    status: "Sent",
    subject: "Fly to Iran with return fares as low as AED 1,304",
    title: "Fly to Iran with return fares as low as AED 1,304",
  },
  {
    processedCount: 5,
    deliveredCount: 5,
    openedCount: 4,
    unopenedCount: 1,
    clickedCount: 0,
    processedPercentage: "100 %",
    openedPercentage: "80.00",
    unopenedPercentage: "20.00",
    clickedPercentage: "0.00 %",
    editMode: "tool2",
    editType: "full",
    id: 238624,
    idType: "Campaign",
    sendTimeStart: "June 22, 2021 at 18h26 UTC",
    starred: false,
    status: "Sent",
    subject: "Fly to Iran with return fares as low as AED 1,304",
    title: "Fly to Iran with return fares as low as AED 1,304",
  },
  {
    processedCount: 5,
    deliveredCount: 5,
    openedCount: 3,
    unopenedCount: 2,
    clickedCount: 0,
    processedPercentage: "100 %",
    openedPercentage: "60.00",
    unopenedPercentage: "40.00",
    clickedPercentage: "0.00 %",
    editMode: "tool2",
    editType: "full",
    id: 238620,
    idType: "Campaign",
    sendTimeStart: "June 22, 2021 at 18h17 UTC",
    starred: false,
    status: "Sent",
    subject: "Fly to Iran with return fares as low as AED 1,304",
    title: "Fly to Iran with return fares as low as AED 1,304",
  },
  {
    processedCount: 49999,
    deliveredCount: 49999,
    openedCount: 6649,
    unopenedCount: 43350,
    clickedCount: 904,
    processedPercentage: "100 %",
    openedPercentage: "13.30",
    unopenedPercentage: "86.70",
    clickedPercentage: "13.60 %",
    editMode: "tool2",
    editType: "full",
    id: 237852,
    idType: "Campaign",
    sendTimeStart: "June 22, 2021 at 03h00 UTC",
    starred: false,
    status: "Sent",
    subject:
      "For AED 1,300 return fare, get away to Sharm El Sheikh with flydubai",
    title:
      "For AED 1,300 return fare, get away to Sharm El Sheikh with flydubai",
  },
  {
    processedCount: 49999,
    deliveredCount: 49999,
    openedCount: 18484,
    unopenedCount: 31515,
    clickedCount: 941,
    processedPercentage: "100 %",
    openedPercentage: "36.97",
    unopenedPercentage: "63.03",
    clickedPercentage: "5.09 %",
    editMode: "tool2",
    editType: "full",
    id: 237136,
    idType: "Campaign",
    sendTimeStart: "June 21, 2021 at 03h00 UTC",
    starred: false,
    status: "Sent",
    subject: "Fly to Sharm El Sheikh for AED 1,300 with flydubai",
    title: "Fly to Sharm El Sheikh for AED 1,300 with flydubai",
  },
]

export { campaigns }
