import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getFirebaseBackend } from "../../helpers/firebase_helper";

import {
  addCampaignFail,
  addCampaignSuccess,
  getCampaignsFail,
  getCampaignsSuccess,
} from "./actions";
import {
  ADD_BULK_CAMPAIGNS,
  ADD_NEW_CAMPAIGN,
  GET_CAMPAIGNS,
} from "./actionTypes";

const fireBaseBackend = getFirebaseBackend();

function* fetchCampaigns() {
  try {
    const response = yield call(fireBaseBackend.getCampaigns);
    yield put(getCampaignsSuccess(response));
  } catch (error) {
    yield put(getCampaignsFail(error));
  }
}

function* onAddBulkCampaigns() {
  try {
    const response = yield call(fireBaseBackend.addBulkCampaigns);
    yield put(addCampaignSuccess(response));
  } catch (error) {
    yield put(addCampaignFail(error));
  }
}

function* onAddNewCampaign({ payload: campaign }) {
  try {
    const response = yield call(fireBaseBackend.addNewCampaign, campaign);
    yield put(addCampaignSuccess(response));
  } catch (error) {
    yield put(addCampaignFail(error));
  }
}

export function* watchCampaign() {
  yield takeEvery(GET_CAMPAIGNS, fetchCampaigns);
  yield takeEvery(ADD_NEW_CAMPAIGN, onAddNewCampaign);
  yield takeEvery(ADD_BULK_CAMPAIGNS, onAddBulkCampaigns);
}

function* CampaignsSaga() {
  yield all([fork(watchCampaign)]);
}

export default CampaignsSaga;
