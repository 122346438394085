/* CAMPAIGNS */
export const GET_CAMPAIGNS = "GET_CAMPAIGNS"
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS"
export const GET_CAMPAIGNS_FAIL = "GET_CAMPAIGNS_FAIL"

/* CAMPAIGNS DETAIL*/
export const GET_CAMPAIGN_DETAIL = "GET_CAMPAIGN_DETAIL"
export const GET_CAMPAIGN_DETAIL_SUCCESS = "GET_CAMPAIGN_DETAIL_SUCCESS"
export const GET_CAMPAIGN_DETAIL_FAIL = "GET_CAMPAIGN_DETAIL_FAIL"

/**
 * add user
 */
export const ADD_BULK_CAMPAIGNS = "ADD_BULK_CAMPAIGNS"
export const ADD_NEW_CAMPAIGN = "ADD_NEW_CAMPAIGN"
export const ADD_CAMPAIGN_SUCCESS = "ADD_CAMPAIGN_SUCCESS"
export const ADD_CAMPAIGN_FAIL = "ADD_CAMPAIGN_FAIL"

/**
 * Edit user
 */
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN"
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS"
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL"

/**
 * Delete user
 */
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN"
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS"
export const DELETE_CAMPAIGN_FAIL = "DELETE_CAMPAIGN_FAIL"
