import { combineReducers } from "redux";
import Login from "./auth/login/reducer";
import campaigns from "./campaigns/reducer";

const rootReducer = combineReducers({
  Login,
  campaigns,
});

export default rootReducer;
