import {
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_FAIL,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGN_DETAIL,
  ADD_BULK_CAMPAIGNS,
  ADD_NEW_CAMPAIGN,
  ADD_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  GET_CAMPAIGN_DETAIL_FAIL,
  GET_CAMPAIGN_DETAIL_SUCCESS,
} from "./actionTypes"

export const getCampaigns = () => ({
  type: GET_CAMPAIGNS,
})

export const getCampaignsSuccess = campaigns => ({
  type: GET_CAMPAIGNS_SUCCESS,
  payload: campaigns,
})

export const addBulkCampaigns = () => ({
  type: ADD_BULK_CAMPAIGNS,
})

export const addNewCampaign = campaign => ({
  type: ADD_NEW_CAMPAIGN,
  payload: campaign,
})

export const addCampaignSuccess = campaign => ({
  type: ADD_CAMPAIGN_SUCCESS,
  payload: campaign,
})

export const addCampaignFail = error => ({
  type: ADD_CAMPAIGN_FAIL,
  payload: error,
})

export const updateCampaign = campaign => ({
  type: UPDATE_CAMPAIGN,
  payload: campaign,
})

export const updateCampaignSuccess = campaign => ({
  type: UPDATE_CAMPAIGN_SUCCESS,
  payload: campaign,
})

export const updateCampaignFail = error => ({
  type: UPDATE_CAMPAIGN_FAIL,
  payload: error,
})

export const deleteCampaign = campaign => ({
  type: DELETE_CAMPAIGN,
  payload: campaign,
})

export const deleteCampaignSuccess = campaign => ({
  type: DELETE_CAMPAIGN_SUCCESS,
  payload: campaign,
})

export const deleteCampaignFail = error => ({
  type: DELETE_CAMPAIGN_FAIL,
  payload: error,
})

export const getCampaignsFail = error => ({
  type: GET_CAMPAIGNS_FAIL,
  payload: error,
})

export const getCampaignDetail = campaignId => ({
  type: GET_CAMPAIGN_DETAIL,
  campaignId,
})

export const getCampaignDetailSuccess = campaignDetails => ({
  type: GET_CAMPAIGN_DETAIL_SUCCESS,
  payload: campaignDetails,
})

export const getCampaignDetailFail = error => ({
  type: GET_CAMPAIGN_DETAIL_FAIL,
  payload: error,
})
