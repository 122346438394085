import { lazy } from "react";

const Login = lazy(() => import("../views/Login"));
const Logout = lazy(() => import("../views/Logout"));

const Dashboard = lazy(() => import("../views/Dashboard"));
const Campaigns = lazy(() => import("../views/Campaigns"));
const CampaignDetails = lazy(() => import("../views/CampaignDetails"));

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/campaigns", component: Campaigns },
  { path: "/campaigns/:id", component: CampaignDetails },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { userRoutes, authRoutes };
